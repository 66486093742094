import jwt_decode from "jwt-decode";


const logOut = () => {
  localStorage.removeItem("token");
};

const logIn = (token) => {
  localStorage.setItem("token", `Bearer ${token}`);
};

const getUserId = () => {
  const token = localStorage.getItem("token");
  if (token){
    const decoded = jwt_decode(token);
    if (decoded.exp < new Date()/1000) return null;
    return decoded.id;
  }
  return null;
};

const getToken = () => {
  const token = localStorage.getItem("token");
  if (token){
    const decoded = jwt_decode(token);
    if (decoded.exp < new Date()/1000) return null;
    return token;
  }
  return null;
};



export default { logIn, logOut, getUserId, getToken };
