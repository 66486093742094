import "./button.scss";
import classnames from "classnames";
import React, { Fragment } from "react";

const Button = ({ id, label, color, column, type, handler, disabled }) => {
  return (
    <Fragment>
      {type === "submit" ? (
        <button
          id={id}
          type={type}
          disabled={disabled}
          className={classnames("button", {
            "button-green": color === "green",
            "button-blue": color === "blue",
            "button-red": color === "red",
            "button-grey": color === "grey",
            "button-black": color === "black",
            "button-disable": disabled,
          })}
        >
          {label}
        </button>
      ) : (
        <button
          id={id}
          type={type}
          disabled={disabled}
          className={classnames("button", "button-hidden", {
            "button-green": color === "green",
            "button-blue": color === "blue",
            "button-red": color === "red",
            "button-grey": color === "grey",
            "button-black": color === "black",
            "button-disable": disabled,
          })}
          onClick={() => handler()}
        >
          {label}
        </button>
      )}
    </Fragment>
  );
};

export default Button;
